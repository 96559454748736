import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  animationTrigger = {
    start:false,
    complete:false
  };
  constructor() {

  }

  ngOnInit() {
    setTimeout(()=>{
      this.animationTrigger.start = true;
    },100);
  }

}
