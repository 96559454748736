import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-animation',
  templateUrl: './home-animation.component.html',
  styleUrls: ['./home-animation.component.scss']
})
export class HomeAnimationComponent implements OnInit, OnDestroy {
  private imgLoadCounter:number = 0;
  private stopInterval:any = null;
  private stopTimer:any = null;
  percentageLoaded:number = 0;
  images: any = [
    'assets/logo/logo-road.png',
    'assets/logo/logo-text.png',
    'assets/img/children.png',
    'assets/img/kangaroo.png',
    'assets/img/leaf.png',
    'assets/img/england-clock.png',
    'assets/img/maple-leaf.png',
    'assets/img/The-Statue-of-Liberty.png',
    'assets/img/road-background.png'
  ];
  animationTrigger= {
    start:false,
    complete:false,
    transition:false,
    showMenu:false
  };

  constructor(private router:Router) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // just in case rendering got interrupted
    clearTimeout(this.stopTimer);
    clearInterval(this.stopInterval);
  }

  imageLoadingTracker() {
      this.imgLoadCounter++;
  }

  readyToRender() {
    this.stopTimer = setTimeout(()=>{
      this.animationTrigger.start = true;
        this.stopTimer = setTimeout(()=> {
          this.stopInterval = setInterval(()=>{
            // wait until images are all loaded for next page
            if(this.imgLoadCounter === this.images.length) {
              clearInterval(this.stopInterval);
              this.animationTrigger.complete = true;
              this.stopTimer = setTimeout(()=>{
                this.router.navigate(['/home']);
              },3500);
            }
          },200);
        },3000);
    },500);
  }
}
