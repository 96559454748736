export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    "COMMON":{
      "WAIT":"Please wait...",
      "CONSTRUCTION":"Under Construction"
    },
    "HEADER": {
      "EN":"EN",
      "FAN":"繁",
      "SIM":"简",
      "ABOUT_ROUTE":"About Route",
      "SHORT_STUDY_ABROAD":"Short Study Abroad",
      "STUDY_ABROAD":"Study Abroad",
      "ONLINE_STUDY":"Online English Study",
      "EXP_SHARE":"Experience Share",
      "CONTACT":"Contact Us"
    },
    "FOOTER": {
      "ABOUT_ROUTE":"About Route",
      "ABOUT_FOCUS":"Our Focus",
      "ABOUT_BRAND":"Brand Story",
      "ABOUT_EXP":"Our Experience",
      "SERVICE":"Services",
      "STUDY_ABROAD":"Study Abroad",
      "CONSULTATION":"Consultation",
      "SUMMER_CAMP":"2018 Summer Camp",
      "EXP_SHARE":"Testimonials",
      "CONTACT":"Contact Us"
    },
    "HOME":{
      "AMERICA":"America",
      "CANADA":"Canada",
      "ENGLAND":"England",
      "NEWZEALAND":"New Zealand",
      "AUSTRALIA":"Australia"
    }
};
