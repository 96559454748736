import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  animationTrigger= {
    homeTop:{children:false,kangaroo:false,leaf:false,clock:false,mapleLeaf:false,statue:false,roadOverlay:false}
  };
  constructor(public gd:GlobalDataService) {
  }
  homeTopAnimation(){
    let step = 0;
    for(let key in this.animationTrigger.homeTop){
      setTimeout(()=>{
        this.animationTrigger.homeTop[key] = true;
        if(key == "roadOverlay"){ /* last */
          setTimeout(()=>{
            this.gd.shareObj.showHeader = true;
            this.gd.shareObj.homeAnimation = true;
          },700);
        }
      },step);
      if(key == "children"){
        step = 1500;
      }
      else if(key == "statue"){
        step = step + 600;
      }else{
        step = step + 400;
      }
    }
  }
  imageLoaded(){
    if(!this.gd.shareObj.homeAnimation){
      this.homeTopAnimation();
    }else{
      this.animationTrigger= {
        homeTop:{children:true,kangaroo:true,leaf:true,clock:true,mapleLeaf:true,statue:true,roadOverlay:true}
      };
    }
  }
  ngOnInit() {
    //this.homeTopAnimation();
  }
}
