import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { TranslateService } from './translate.service'; // our translate service
// SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl 
@Pipe({
  name: 'translate',
  pure: false // impure pipe, update value when we change language
})
export class TranslatePipe implements PipeTransform {

  constructor(private _translate: TranslateService, protected sanitizer: DomSanitizer) { }

	transform(value: string, args: string): any {
		if (!value) return;
		let translateStr = this._translate.instant(value);
		if(args === 'html'){
			return this.sanitizer.bypassSecurityTrustHtml(translateStr);
		} else {
			return translateStr;
		}
	}
}
