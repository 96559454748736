import {Injectable, Inject} from '@angular/core';
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang/en';
import { LANG_zhCN_NAME, LANG_zhCN_TRANS } from './lang/zhCN';
import { LANG_zhTW_NAME, LANG_zhTW_TRANS } from './lang/zhTW';

@Injectable()
export class TranslateService {
	private defaultLang : string = 'zhTW';
	private _currentLang: string;
	private _translations: any = {
		[LANG_EN_NAME]: LANG_EN_TRANS,
		[LANG_zhCN_NAME]: LANG_zhCN_TRANS,
		[LANG_zhTW_NAME]: LANG_zhTW_TRANS
	};;
	public get currentLang() {
	  return this._currentLang;
	}

  // inject our translations
	constructor() {

	}

	public use(lang: string): void {
		// set current language
		this._currentLang = lang || this.defaultLang;
	}

	private translate(key: string): string {

		// private perform translation
		let keys = key.split(".");
		if(keys.length == 2){
			let lang = localStorage.getItem('route-lang');
			if(!lang){
				lang = this.defaultLang;
				localStorage.setItem('route-lang', lang);
			}
			if (this._translations[lang] && this._translations[lang][keys[0]]) {
				return this._translations[lang][keys[0]][keys[1]];
			}
		}
		return key;
	}

	public instant(key: string) {
		// public perform translation
		return this.translate(key);
	}
}
