import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// services
import { TranslateService }   from './modules/translate/translate.service';
import { TranslatePipe }   from './modules/translate/translate.pipe';
import { GlobalDataService } from './services/global-data.service';

// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeAnimationComponent } from './components/home-animation/home-animation.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AboutComponent } from './components/about/about.component';
import { ShortStudyAbroadComponent } from './components/short-study-abroad/short-study-abroad.component';
import { StudyAbroadComponent } from './components/study-abroad/study-abroad.component';
import { OnlineEnglishStudyComponent } from './components/online-english-study/online-english-study.component';
import { ContactComponent } from './components/contact/contact.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { DevelopmentLogComponent } from './development-log/development-log.component';


const appRoutes: Routes = [
  { path: '',component:HomeAnimationComponent },
  { path: 'home',component:HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'short-study-abroad', component: ShortStudyAbroadComponent },
  { path: 'study-abroad', component: StudyAbroadComponent },
  { path: 'study-abroad/:section', component: StudyAbroadComponent },
  { path: 'online-english-study', component: OnlineEnglishStudyComponent },
  { path: 'testimonials', component: TestimonialComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'development-log', component: DevelopmentLogComponent },
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeAnimationComponent,
    TranslatePipe,
    PageNotFoundComponent,
    AboutComponent,
    ShortStudyAbroadComponent,
    StudyAbroadComponent,
    OnlineEnglishStudyComponent,
    ContactComponent,
    TestimonialComponent,
    HomeComponent,
    DevelopmentLogComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule
  ],
  providers: [TranslateService,GlobalDataService],
  bootstrap: [AppComponent]
})
export class AppModule {

}
