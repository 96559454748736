export const LANG_zhTW_NAME = 'zhTW';

export const LANG_zhTW_TRANS = {
    "COMMON":{
      "WAIT":"請稍後...",
      "CONSTRUCTION":"施工中"
    },
    "HEADER": {
      "EN":"英",
      "FAN":"繁",
      "SIM":"简",
      "ABOUT_ROUTE":"關於路由",
      "SHORT_STUDY_ABROAD":"微留學",
      "STUDY_ABROAD":"留學",
      "ONLINE_STUDY":"線上英語教學",
      "EXP_SHARE":"經驗分享",
      "CONTACT":"聯絡我們"
    },
    "FOOTER": {
      "ABOUT_ROUTE":"關於路由",
      "ABOUT_FOCUS":"路由宗旨",
      "ABOUT_BRAND":"品牌故事",
      "ABOUT_EXP":"團隊經歷",
      "SERVICE":"服務項目",
      "STUDY_ABROAD":"留(遊)學規劃",
      "CONSULTATION":"顧問服務",
      "SUMMER_CAMP":"2018夏令營",
      "EXP_SHARE":"經驗分享",
      "CONTACT":"聯絡我們"
    },
    "HOME":{
      "AMERICA":"美國",
      "CANADA":"加拿大",
      "ENGLAND":"英國",
      "NEWZEALAND":"紐西蘭",
      "AUSTRALIA":"澳大利亞",
      "M1":"一同探索孩子未來的“路”",
      "M2":"以最專業的服務<br>從選校到後留學規劃建議<br>提供您明智之選<br>專業的顧問團隊，一路相伴",
      "F1":"而決策過程當中提供最專業，可行的選項，是我們深摯的期望。",
      "F2":"教育是通往未來的護照，而明天，屬於準備好的人",
      "P1":"路由教育是一所留學教育顧問機構。致力於幫助學生找到他們的學術及個人目標；協助學生在世界的茫茫學海中，找到適合的學校，得以在此深造學習，朝著目標前進。",
      "P2":"我們在行業中獨樹一幟，<span class='color-red'>因為我們的服務並不局限在特定清單中選校，而是涵蓋所有學校。</span>經由量身定做的課程規劃，個性化的專屬顧問諮詢，家長與學生通暢的溝通平台，路由為每個家庭提供一份只屬於您孩子的長期教育規劃，與您建立長期的良好合作關係，是我們的目標。",
      "P3":"路由的任務，不只是送學生出國讀書。我們的宗旨是幫助每個學生，在自我學術的提升上，有著更鞏固的基礎與有效率的學習方法。為達成此目標，我們需要透徹的了解每個家庭的需求與現狀，並據此提供更適合的留學規劃提案，以協助您完成著重大的決策。",
      "P4":"路由的服務，為了上述的目標，分成幾個階段：出國前能力培養，選出最適合的學校，協助完成申請，以及留學生活規劃。另外，為了在各個階段提供全方位的協助，不同於其他中介留學規劃的服務，我們每個學生將由多個顧問根據他們自身的經歷及專業，提供多視角的綜合建議及長期規劃。"

    },
    "ABOUT": {
      "L1":"我們來自不同國家，各自擁有不同的留學經驗，想藉此幫助更多孩子創造美好的留學經歷。路由的宗旨是協助家長同孩子做教育規劃。在成長的路上找出適合他們自己的“路”。不單純追求排名，更不只是申請學校。除了行前準備，學校挑選外，更重視在學期間的輔導。",
      "L2":"<b>Michelle</b> 來自台灣，年少負笈澳洲，先後就讀私立女子寄宿中學聖凱瑟琳學院和墨爾本大學。畢業後就職於廣告業巨頭李奧貝納，並多次創業。美維在曾在台北、上海、墨爾本、基督城等地居住生活，足跡遍及五大洲，對東西方多元文化有深入的體會和獨特的見解。 Michelle在教育和教育諮詢方面具有濃厚的興趣和熱情，作為路由的聯合創始人，她提出以持續跟進、貼心輔導作為路由的服務特色，並在此理念下成功運作過多位程度、背景不同的學生赴美國和新西蘭留學深造。",
      "L3":`<p><b>Ti</b> 老師是位專業的口語老師</p>
            <p>他不只是“會講英文所以教英文”的老師而已。他受過的語言訓練是“正統自我表達課程”的訓練。 </p>
            <p>自小身長於多倫多，Ti 是知名Randolph音樂學院中的表演藝術學系。 Randolph許多校友即是紐約百老彙的專業表演者，更甚至有幾位是現任好萊塢知名影星。 </p>
            <p><b>Ti</b> 老師更是他在校當屆近10年唯一被Randolph錄取的亞裔華人。除了專業舞蹈，歌唱與演繹專精之外，Ti老師也是正統皇家音樂學院演奏者證照的鋼琴家。 </p>
            <p><b>Ti</b> 老師在Randolph表演藝術學系在英語擁有職業Triple Threat專精資格。在Randolph學院，Triple Threat代表學員必須有專業水準的歌唱，舞蹈，與演譯的專精。 </p>
            <p>許多學員的目標都是站上大舞台表演，因此學員必須經過許多專業的演繹鍛煉。其中包含專業的“發聲課程”，各種不同門“口音精通訓練”，與上台該有的專長如“肢體語言”以及“發言自信心”等訓練。 </p>
            <p><b>Ti</b> 老師將他所受過的訓練，現在運用在他自己所規劃的語文課程當中，教導他學生該如何達到完美與專業的自我表達。 </p>
            <p>他的學生遍布全世界，不僅是“非英語母語者”學習英文的階段會上Ti老師的課而已，許多老師，醫師，其他社會專業人士，以及“英語母語者”，都曾是受過Ti老師表達訓練的學生。 </p>
            `      
    },
    "TEST": {
      "OPEN" : "展開全文",
      "CLOSE" : "縮小全文",
      "JERRY_L1" : "Jerry 同學",
      "JERRY_L2" : "美國倫斯勒理工學院<br>Rensselaer Polytechnic Institute<br>工程專業 大一學生",
      "JERRY_L3" : "求學歷程：溫州公立小學&中學<br>上海公辦高中<br>美國 Hebron Academy 高中<br>美國倫斯勒理工學院",
      "JERRY_L4" : "2015年1月底：路由協助開始申請美高<br>2015年2月底：拿到美國Hebron Academy暑期班及11年級Offer<br>2015年8/16～9/6：參加錄取高中Hebron Academy的暑期班<br>2015年9月～2017年6月：就讀11年級，12年級<br>2016年9月初：開始著手申請美國大學<br>2017年1月中旬：收到倫斯勒理工學院  提前錄取（ED）通知<br>2017年9月～至今：倫斯勒理工學院  工程專業  大一學生",
      "SEAN_L1": "新西蘭私立基督中學<br>Christ's College 13年級",
      "SEAN_L2": "13年級的學生Sean很喜歡學校提供的各種各樣的科目，而且他很享受可以自己選課的自由。 “在中國，學校裡只有安排好的課程表，但在新西蘭，你的課程表是由你來管理你的時間，你可以選擇自己喜歡的活動和想要學習的科目。”Sean最感興趣科目的是藝術（設計和繪畫）。他想要保持自己的創造性，並想把設計發展為自己的未來職業。",
      "SEAN_L3": "ean在夏季參加了田徑運動會，並且在冬季踢足球。同時，他也很享受學校提供的多種多樣的課餘活動，他說這些有趣的活動是一個結識新朋友的好方式。",
      "SEAN_L4": "求學歷程：<br>溫州公立小學<br>杭州公立中學<br>新西蘭基督城男子私立基督中學",
      "SEAN_L5": "2013年3月底：前來委託<br>2014年4月中旬：拿到美國暑期班Offer<br>2014年6/30～7/26：參加美國Portsmouth Abbey高中暑期班<br>2014年8月～12月：路由定制化英語加強訓練& 模擬國外高中生活作息<br>2014年11月中旬：拿到新西蘭基督中學Christ's College錄取通知<br>2015年1月：基督城四周語言學校熟悉環境< br>2015年2月～至2018年1月：入學10年級～現讀13年級",
      "SEAN_L6": "<u>新西蘭Christ's College高中三年留學點滴</u>",
      "SEAN_L7": "很多人問我，在新西蘭留學，是怎樣的體驗？大家都以為，除了抱羊還是羊 :) :-] 那你就錯了，往下看看我三年來在基督城寄宿高中的校內外活動。",
      "SEAN_L8": "我的學校是一所沿襲典型英式貴族傳統的私立名校，源自英國國教的教會學校。是新西蘭最古老的私立男子中學，已有超過150年的歷史。英式精英的培養方式，位處於基督城市中心，一邊緊鄰著世界第二大的海格力公園，另一邊為坎特伯雷博物館。校園以最新的建築標準融合保留最古老的傳統建築外觀，校園裡還有一座壯觀的教堂保留著。地震後學校一共投入了人民幣約4億多元做了老建築加固。學習中心裡的課後1對1單獨輔導，是不另外收費的。學生一律穿校服，而所有校服均有專人負責洗燙。為訓練學生自理能力，便服則是需要學生自己用宿舍內洗衣機洗。",
      "SEAN_L9": "學校的日常生活作息規定非常完整、嚴密。起床、吃早餐、上下課時間，下課後每個人都必須參加體育俱樂部，鍛煉結束回宿舍洗澡、晚自習到熄燈時間。所有事情都有嚴格的時間安排。學生其實每天的自由時間有限，不是在學習，就是在鍛煉體能。",
      "SEAN_L10": "宿舍裡面有樓長、生活老師、教學輔導員三組人馬。宿舍牆上，刻著住過的學生名字。宿舍裡有學生自習的教師，晚上有專門的老師陪同。睡覺前，學生的手機電腦必需交給老師鎖起來。強調集體宿舍成長，強烈的集體榮譽感、團隊配合、分享。共同生活的經驗。",
      "SEAN_L11": "<p><u>家長心路歷程分享</u></p><p>遇見路由的Michelle老師之前，我是一個徬徨無助的媽媽，看著體弱多病的兒子經常去醫院邊打點滴邊寫作業很心痛也無耐。成績能還在不上不下中徘徊，性格越來越沉默，自信全無。直到有一天，他放學回家很傷心的和我說：“媽，我在班裡被劃為“垃圾那類了”，我瞬間奔潰意識到不能這樣下去了，我帶著兒子求助了Michelle 。她馬上著手安排和規劃在最短的時間里安排好了兒子暑期去美國上暑期班事宜，直到兩月後我們去接他，看到了他用蹩腳的英文去和人溝通一次又一次而且臉上是笑容滿面的自信，這是我們無法想像的。而後我們在Michelle的安排下，居然申請到了新西蘭最古老的私立中學。排名前十的基督中學 Christ’s College。我們在感恩上帝的同時也十分感謝Michelle的用心和付出，到現在已經過去了快三年的時間，我兒子身上可以說發生了翻天覆地的變化：戲劇、繪畫、音樂、體育一樣沒少，還是個社交活動上的活躍分子。最主要的是身體強壯了，開朗、自信回到了他身上。而這三年兒子留學期間，也還好有Michelle幫我們除了翻譯成績單、繳交學費外，協助選科，跟老師溝通學期學習進度，討論及安排需要加強的項目。跟兒子定期保持聯繫，了解他的學術及生活狀況，鼓勵他從事樂器學習，課後社團與運動等等。也是我們跟監護人之間的溝通橋樑，安排學期結束後的放假去處。很多沒想到的事，Michelle都提前提醒我們。兒子的成長，我們看在眼裡。謝謝Michelle，因為有你，我們很安心。 </p>",
      "SEAN_L12": `<p><u>Sean的感想</u></p><p>我講講我到新西蘭這三年來的收穫。剛剛來到新西蘭的時候，我英語還沒有現在那麼好。只是我沒有害羞去和外國人交談，所以不僅交到許多外國朋友，還有我的英語口語也隨之進步了許多。還有一件事情我覺得比較重要的是剛到學校的時候，不要忘記多多去嘗試從來沒有嘗試過的東西。像當地人愛玩的體育項目我們國際生也可以去加入。當然也可以問當地人教我們玩，這其實也是融入當地人團體的方式。不僅是體育項目，還有要多嘗試不同的科目，這樣方便以後選到自己喜愛的科目。對於我來說，我現在選到了我一直感興趣的設計和油畫，這有點走藝術範。 </p>
                    <p>總而言之，我覺得到不論是國外讀書、旅遊或是工作，要敢於和外國人交流，這樣對我們國際生的口語方面幫助是非常大的。外國人很愛運動，多去玩玩運動也是交朋友的方式。 </p>
                    <p>順便講講我是怎麼順利的進入Christ‘s College的。我在中國的時候，我也是不敢跟金發碧眼的國外人交流的。轉折點就是，我有一位超級美麗的英語老師Teacher Ti。苦口婆心的一直反复鍛煉我的英語，所以和外國人交流時，不會小鹿亂撞。而在這位美似天仙級別的英語老師的天仙（魔鬼）訓練下，我那時候敢和外國人溝通並且可以開幾個玩笑。在這裡要再次謝謝 Teacher Ti 對我的幫助。不僅僅有Ti老師對我英語的鍛煉，還有Michelle老師幫我找到這麼一所學校。我認為這所學校真的給了我人生的一個轉折點。 Michelle老師是我的引路人，我一路能夠順利的進入這所學校，少不了她一直給學校寫郵件，還有少不了她教我如何應對面試。總之，沒有Michelle 和 Ti 對我的幫助，或許我的轉折點也不會出來。 </p>
                    `,
      "TIMMY_L1": `<p>曼徹斯特大學位於城市的市中心，生活出行很方便，所以到曼徹斯特不久即使不怎麼習慣獨立生活也能馬上適應那裡的生活。 </p>
      <p>宿舍有投幣洗衣機，小超市快餐店也有很多宿舍步行就能到學校，又沒有早課可以充分保證睡眠。課不是每天都有，形式多樣化，有大教室的講座也有小組形式的課程，輔導老師比起教授很多，知識更主要是給予指導所以想要拿到好成績需要自己多看書，能鍛煉自己學習的能力。 </p>
                    <p>學校有很大的圖書館和學習中心，平時總是擠滿了學生，氣氛很棒，在曼徹斯特可以得到很好的學習體驗。 </p>
                    `,
      "LI_L1" : "遇見Route Ed.之前，我是一個徬徨無助的媽媽，看著體弱多病的兒子經常去醫院邊打點滴邊寫作業很心痛也無耐，成績能還在不上不下中徘徊，性格越來越沉默，自信全無。直到有一天，他放學回家很傷心的和我說：“媽在班裡被劃為“垃圾那類了”，我瞬間奔潰意識到不能這樣下去了，我帶著兒子求助了Route Ed. ，她們馬上著手安排和規劃在最短的時間里安排好了兒子暑期去美國上暑期班事宜，直到兩月後我們去接他，看到了他用蹩腳的英文去和人溝通一次又一次而且臉上是笑容滿面的自信的，這是我們無法想像的，而後我們在Route Ed.的安排下居然申請到了新西蘭排名前十的基督教男子中學，我們在感恩上帝的同時也十分感謝Route Ed.的用心和付出，到現在已經過去了兩年半的時間，我兒子身上可以說發生了翻天覆地的變化，戲劇、繪畫、音樂、體育一樣沒落還是個社交活動上的活躍分子，最主要的是身體強壯了，開朗、自信回到了他身上，再次感謝Route Ed.的幫助。",
      "WANG_L1" : `<p>去年四月經親戚介紹認識了陳老師，從我們開始有意向送兒子出國留學到申請成功只用了短短的五個月時間。說起我兒子真是有一肚子的苦水，他從初中開始就是一個問題少年，上學經常被老師批評，最後發展到逃學。就這樣在我們家長軟硬兼施下勉勉強強上完高中。高考也沒參加就外出四處遊蕩瞎折騰了好幾年，也混不出什麼名堂。我們全家都為之苦惱，束手無策！遇到陳老師後我就把我兒子的情況和我們家長的想法告訴她，請她幫忙設計我兒子重上求學之路。 </p>
                  <p>後來陳老師約我兒子與她見面，通過了解交談，陳老師認為我兒子這幾年的社會闖蕩和挫折內心有求學的慾望。在陳老師的幫助和指導下，一切申請手續都很順利。於2016年9月下旬踏上了新西蘭留學之路！實現了大學夢！ </p>
                  <p>我兒子出國留學已整整一年時間了，現在已完成預科課程進入大一班學習。我們從起初擔心到後來的放心再到現在的全家開心。在出國留學前我們還怕兒子基礎差又綴學這麼多年會堅持不下來，可是到了林肯大學後就被這裡的環境和老師深深地吸引住了，兒子就像換了一個人似的，一向厭學的他對學習開始感興趣起來了。平時也願意跟我們交流商討在校一些學習情況。最近又傳來捷報三門功課名列前茅，我們懸著的心終於放下來了。這些成績的獲取離不開兒子的努力和付出，希望兒子能再接再厲，再上一個台階！同時也離不開陳老師的一路關心和指導，每次遇到問題時她總是不厭其煩幫助和支持，並主動與學校老師聯繫解決我們的實際困難。特別是今年8月份還專程赴新西蘭學校拜訪任課老師了解學生在學校的學習和生活情況。在此我們表示衷心的感謝！祝愿陳老師生活幸福安康，事業興旺發達！ </p>
                `
    },
    "ONLINE" : {
      "L1" : "路由在提供留學規劃的過程中，根據個別學生不同的需求，有專門一對一的老師可供學生額外線上上課，做針對性的輔導，提升語言能力或英語考試成績以面對申請學校需求。"
    },
    "STUDY" : {
      "TAB1" : "留學服務",
      "TAB2" : "留學期間顧問服務",
      "TAB3" : "留學行前準備",
      "TAB1_L1" : `<h3>個性化、細節化的全方位留學服務</h3>
                    <p>留學是一個孩子成長的過程，而不是他（她）的人生終點。能支持孩子一路堅持走下去的，不是進了一間最好的學校。而是這個學校，是不是他（她）自己認為可以達成目標與夢想的所在。我們相信，每個孩子都有一份被上天隱藏的禮物，必須一次次追尋，才能獲得。我們的使命，是一同和您探索孩子未來的路，尋找這份禮物。 </p>
                    <p>出國留學並非只是把英文學好，走馬看花那麼簡單。它是一個讓孩子有機會走出國門，拓展視野的機會；經由脫離安全溫暖的家，在外多去嘗試不同事物，與不同的人相處，從中發覺自己的興趣，找到最愛的目標。而有了動力，進而累積自己的專業。不同的旅程所帶給生命的刺激，就像葡萄酒會經過歲月曆練而發生奇妙的化學變化。不同的際遇，會開啟孩子不同的未來之路。 </p>
                    <p><b>先謀而後動</b></p>
                    <p>謀劃準確周到而後行動。選校不要盲目。沒有最好的學校，只有適不適合孩子。匯總專業留學顧問建議再決定。一系列的客制化方案，供您全方位的選擇出最適合您家庭教育目標。 </p>
                    <p><b>謀</b>：經由深入了解，鎖定誘因，找出動力，全盤考量，顧問團隊整合製定多視角的綜合建議及長期規劃。 </p>
                    <p><b>動</b>：出國前能力培養，選出合適的學校，申請入校，後留學學術及生活規劃</p>                    <p>路由致力于帮助客户在繁复的学校申请流程中，提供充足的咨询，全面的协助，以及专业的建议。经验丰富且热情的专业顾问，经由紧密的与学生及家长合作，帮助选定最适合的近程及远程目标，订立计划。</p>
                    <p>我們堅信，拿到學校的錄取通知書並不是唯一的終極目標。我們期望除了被錄取的結果之外，能在申請過程當中，讓孩子準備好，迎接將來校園裡的社交與學術挑戰。我們除了希望成為您的得力左右手，也希望是良師益友，一同幫助孩子成為明日的國際領導者。這是路由的初心。每個學生都是獨一無二的。我們將盡全力為您及您的寶貝找出最適合他的學校。無論您是為您的孩子尋找一個短期夏令營，暑期班，或安排出國讀書留學規劃及學校申請。我們的顧問團隊將以專業的手，熱情的心，提供一系列的客制化方案，幫助您達成您的家庭教育目標。 </p>
                    <p>路由與您一起探索孩子未來的路。 </p>
                    <p>成功之路，由此開啟。 </p>
                    `,
      "TAB2_L2" : `<img src="assets/img/study/Ongoing1.jpg" style="margin:.5em; width: 100%; max-width: 200px; float:left;" /><p>路由的任務，不停止於只是送學生出國讀書。我們的宗旨是幫助學生，有更鞏固的基礎及高效的學習方法。並保持心態健康，積極面對生活及人際關係。路由的留學期間顧問服務，在協助完成學校申請後，更提供您下個階段的協助，例如：安排監護人；協助選科；成績單翻譯，跟老師溝通學期學習進度，討論及安排需要加強的項目。跟學生保持亦師亦友的關係，了解孩子的學術及生活狀況，進而鼓勵他們從事休閒娛樂，例如：樂器學習，課後社團與運動等等。使其能獨立自主的為人生負責任地做有效安排。此外，我們亦協助與校方一同安排學期結束後的放假去處。建議是否再進一步參與學校的暑期班課程。輔導給付下一學年度學費賬單等。 </p>
                    <p>不同於其他中介留學規劃的服務，我們每個學生將由多個顧問根據他們自身的經歷及專業，提供多視角的綜合建議及長期留學生活規劃。進入一所學校就讀，只是孩子人生的轉折起點。負責的教育，是我們一同一路陪伴著孩子，從不同的角度，持續性的引導及見證成長。 </p>
                    <p>我們除了希望成為您的得力左右手，也希望是良師益友，一同幫助孩子成為明日的國際領導者。這是路由的初心。 </p>
                    `,
      "TAB3_L3" : `<div class="text-center"><img src="assets/img/study/checklist.jpg" style="margin: .5em; width: 100%; max-width: 200px;" /></div>
                    <p>恭喜你拿到Offer成為準留學生。最困難的階段已經闖關成功。接下來是留學行前需要注意的準備事項。 </p>
                    <ol>
                      <li>護照 & 學生簽證檢查護照有效期是否在六個月以上 & 學生簽證信息是否正確</li>
                      <li>購買機票</li>
                      <li>安排接機 & 住宿</li>
                      <li>體檢:<br>出發前提早至少三週，請至各地市衛生檢疫局體檢並開具（a）國際預 防接種證明和（b）國際旅行健康證國際通用（黃卡)。 </li>
                      <li>拜访牙医&眼科:<br>国外看牙医和眼科不涵盖在国际学生医疗保险内。而且费用非常高。尽可能在国内先拜访牙医跟检查眼睛，配好需要的眼镜或隐形眼镜带出國。</li>
                      <li>手機卡開通全球通業務:<br></li>
                      <li>請在出國前一天暫時開通國內手機卡的全球通業務。或者在淘寶買一張  國際電話卡，以便到達後給家長報平安和聯繫學校接機用。 </li>
                      <li>筆記型電腦:<br>通常國外從高中階段學生開始使用筆記型電腦做功課。可事先了解學校  傾向學生使用的電腦相關規格，從中國購買帶過去或者從學校訂購。 </li>
                      <li>購買旅遊平安險 & 國際學生醫療保險:<br>旅遊平安險可直接網上購買國內保險公司所提供的。只需輸入基本信息跟航班日期。國際學生醫療保險需依照學校規定購買。建議直接請學校代買</li>
                      <li>文件準備:<br>為方便文件被竊或遺失時，能迅速申請補發，所有下列清單物品最好事先掃描留檔。所有正本隨身攜帶。全部文件複印一份，複印件跟護照用證件照一起放托運行李內）(1) 機票 (2) 護照 (3) 學生簽證  (4) 學校錄取通知單 (5) 旅遊平安險 (6) 國際學生醫療保險 (7) 幾張護照用的證件照 (8) 學生本人姓名的國際通用信用卡（通常為家長的副卡）。 </li>                  <li>行李打包:<br>
                        <ul>
                          <li>行李箱:<br>各航空公司跟搭乘艙等規定不同，一般免費帶1～2件托運行李箱（28～30寸，行李箱三邊之和小於158公分。單件重量通常不超過20～23公斤，學生可以申請額外限額）。一個隨身行李箱（通常為20寸，行李箱三邊之和小於118公分。單件重量不超過5～10公斤<br>
                          常用藥品 - 攜帶的藥物最好放在原裝並有標示的瓶罐或盒子裡。如因藥物較特別或含有麻醉品成分，要攜帶醫生開給的證明。自備慣用的藥品要避免攜帶粉狀藥物，以免被誤為毒品。在對攜帶藥物入境是否合法有疑問時，應事先諮詢該國駐華機構或我國駐外使館。 <br>                        充电器 & 转换插头 - 请先查询目的地国家的电压。备好充电器和转换插头。不建议携带电器产品避免危险。飞机充气靠枕长途飞行时可增加舒适度。</li>
                          <li>中國特色小禮物:<br>可以準備一些送給老師或者寄宿家庭。 </li>
                          <li class="color-red">嚴禁攜帶：水果，蔬菜，種子，肉製品，盜版光碟或書籍等。輕者會沒收，重者有處分</li>
                        </ul>
                      </li>
                    </ol>
                  `
    },
    "STUDY_SHORT": {
      "COMMON_L1": "規劃中",
      "COMMON_L2": "下載詳細內容",
      "2018_L1": "<h1>路由2018之約</h1><h2>暑期微留學體驗營@新西蘭</h2><h3>« 路由去哪兒»</h3>2018@新西蘭<br>那一年，我們在基督城避暑",
      "2018_L2": "<h3>行程資訊</h3><p>2018年<br>7月20日～8月31日<br>各梯次出行日期：<br>7/20；8/3；8/17<br >（兩週起報）<br>招生人數<br>4～12個學位<br>招生年齡<br>5～12歲<br>安插當地小學<br>12～18歲<br>安插當地中學&高中<br>12周歲以下的學生必需至少有一名家長全程陪讀</p>"
    }
};
