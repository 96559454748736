import { Component, OnInit, } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalDataService } from './services/global-data.service';
// import { TranslateService } from './modules/translate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'app';
  uiHandler:any;
  constructor(private router:Router, public gd:GlobalDataService) {
    this.uiHandler = {
      showHeader:false
    };
  }

  ngOnInit() {
    setTimeout(()=>{
      if(this.router.url != "/" ){
        this.uiHandler.showHeader = true;
        this.gd.shareObj.showHeader = true;
      }
    });
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
  }
}
