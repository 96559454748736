import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('noAnimation') noAnimation:boolean;
  renderFixedMenu = false;
  public animationTrigger = {
    start:false,
    complete:false
  };
  public uiHandler = {
    lang:"",
    showMenu:false
  };
  constructor() {
    this.uiHandler.lang = localStorage.getItem("route-lang");
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    setTimeout(()=>{
      this.animationTrigger.start = true;
    },100);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (e): void => {
    let pos = document.getElementsByTagName("html")[0].scrollTop;
    if(pos >= 200 && !this.renderFixedMenu) {
      this.renderFixedMenu = true;
    } else if(pos < 100 && this.renderFixedMenu) {
      this.renderFixedMenu = false;
    }
  };
  public changeLang(code){
    localStorage.setItem("route-lang",code);
    this.uiHandler.lang = code;
  }

  public toggleMenu(){
    this.uiHandler.showMenu = !this.uiHandler.showMenu;
  }

  public closeMenu(){
    this.uiHandler.showMenu = false;
  }
}
