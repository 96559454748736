import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }       from '@angular/router';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';

@Component({
  selector: 'app-study-abroad',
  templateUrl: './study-abroad.component.html',
  styleUrls: ['./study-abroad.component.scss']
})
export class StudyAbroadComponent implements OnInit {
  uiCtrl:any = {tabs:[
    {
      name: "service",
      active: true,
    },
    {
      name: "consulation",
      active: false
    },
    {
      name: "planning",
      active: false
    }
  ]};
  section:string=null;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    // this.section = this.route.params._value.section;
    this.route.params.subscribe((params: Params)=>{
      this.section = params.section;
      this.resetTabs(this.section || 'service');
    });
  }

  resetTabs(section:string) {
    for(let i=0; i<this.uiCtrl.tabs.length; i++) {
      this.uiCtrl.tabs[i].active = this.uiCtrl.tabs[i].name === section ? true : false;
    }
  }
}
