export const LANG_zhCN_NAME = 'zhCN';

export const LANG_zhCN_TRANS = {
  "COMMON":{
    "WAIT":"请稍後...",
    "CONSTRUCTION":"施工中"
  },
  "HEADER": {
    "EN":"英",
    "FAN":"繁",
    "SIM":"简",
    "ABOUT_ROUTE":"关於路由",
    "SHORT_STUDY_ABROAD":"微留学",
    "STUDY_ABROAD":"留学",
    "ONLINE_STUDY":"线上英语教学",
    "EXP_SHARE":"经验分享",
    "CONTACT":"联络我们"
  },
  "FOOTER": {
    "ABOUT_ROUTE":"关於路由",
    "ABOUT_FOCUS":"路由宗旨",
    "ABOUT_BRAND":"品牌故事",
    "ABOUT_EXP":"团队经历",
    "SERVICE":"服务项目",
    "STUDY_ABROAD":"留(遊)学规划",
    "CONSULTATION":"顾问服务",
    "SUMMER_CAMP":"2018夏令营",
    "EXP_SHARE":"经验分享",
    "CONTACT":"联络我们"
  },
  "HOME":{
    "AMERICA":"美国",
    "CANADA":"加拿大",
    "ENGLAND":"英国",
    "NEWZEALAND":"纽西兰",
    "AUSTRALIA":"澳大利亚",
    "M1":"一同探索孩子未来的“路”",
    "M2":"以最专业的服务<br>从选校到后留学规划建议<br>提供您明智之选<br>专业的顾问团队，一路相伴",
    "F1":"而决策过程当中提供最专业，可行的选项，是我们深挚的期望。",
    "F2":"教育是通往未来的护照，而明天，属于准备好的人",
    "P1":"路由教育是一所留学教育顾问机构。致力于帮助学生找到他们的学术及个人目标；协助学生在世界的茫茫学海中，找到适合的学校，得以在此深造学习，朝着目标前进。",
    "P2":"我们在行业中独树一帜，<span class='color-red'>因为我们的服务并不局限在特定清单中选校，而是涵盖所有学校。</span>经由量身定做的课程规划，个性化的专属顾问咨询，家长与学生通畅的沟通平台，路由为每个家庭提供一份只属于您孩子的长期教育规划，与您建立长期的良好合作关系，是我们的目标。",
    "P3":"路由的任务，不只是送学生出国读书。我们的宗旨是帮助每个学生，在自我学术的提升上，有着更巩固的基础与有效率的学习方法。为达成此目标，我们需要透彻的了解每个家庭的需求与现状，并据此提供更适合的留学规划提案，以协助您完成着重大的决策。",
    "P4":"路由的服务，为了上述的目标，分成几个阶段：出国前能力培养，选出最适合的学校，协助完成申请，以及留学生活规划。另外，为了在各个阶段提供全方位的协助，不同于其他中介留学规划的服务，我们每个学生将由多个顾问根据他们自身的经历及专业，提供多视角的综合建议及长期规划。"
  },
  "ABOUT": {
    "L1":"我们来自不同国家，各自拥有不同的留学经验，想借此帮助更多孩子创造美好的留学经历。路由的宗旨是协助家长同孩子做教育规划。在成长的路上找出适合他们自己的“路”。不单纯追求排名，更不只是申请学校。除了行前准备，学校挑选外，更重视在学期间的辅导。",
    "L2":"<b>Michelle</b> 来自台湾，年少负笈澳洲，先后就读私立女子寄宿中学圣凯瑟琳学院和墨尔本大学。毕业后就职于广告业巨头李奥贝纳，并多次创业。美维在曾在台北、上海、墨尔本、基督城等地居住生活，足迹遍及五大洲，对东西方多元文化有深入的体会和独特的见解。Michelle在教育和教育咨询方面具有浓厚的兴趣和热情，作为路由的联合创始人，她提出以持续跟进、贴心辅导作为路由的服务特色，并在此理念下成功运作过多位程度、背景不同的学生赴美国和新西兰留学深造。",
    "L3":`<p><b>Ti</b> 老师是位专业的口语老师</p>
          <p>他不只是“会讲英文所以教英文”的老师而已。他受过的语言训练是“正统自我表达课程”的训练。</p>
          <p>自小身长于多伦多，Ti 是知名Randolph音乐学院中的表演艺术学系。Randolph许多校友即是纽约百老汇的专业表演者，更甚至有几位是现任好莱坞知名影星。</p>
          <p><b>Ti</b> 老师更是他在校当届近10年唯一被Randolph录取的亚裔华人。除了专业舞蹈，歌唱与演绎专精之外，Ti老师也是正统皇家音乐学院演奏者证照的钢琴家。</p>
          <p><b>Ti</b> 老师在Randolph表演艺术学系在英语拥有职业Triple Threat专精资格。在Randolph学院，Triple Threat代表学员必须有专业水准的歌唱，舞蹈，与演译的专精。</p>
          <p>许多学员的目标都是站上大舞台表演，因此学员必须经过许多专业的演绎锻炼。其中包含专业的“发声课程”，各种不同门“口音精通训练”，与上台该有的专长如“肢体语言”以及“发言自信心”等训练。</p>
          <p><b>Ti</b> 老师将他所受过的训练，现在运用在他自己所规划的语文课程当中，教导他学生该如何达到完美与专业的自我表达。</p>
          <p>他的学生遍布全世界，不仅是“非英语母语者”学习英文的阶段会上Ti老师的课而已，许多老师，医师，其他社会专业人士，以及“英语母语者”，都曾是受过Ti老师表达训练的学生。</p>
    `
  },
  "TEST": {
    "OPEN" : "展开全文",
    "CLOSE" : "缩小全文",
    "JERRY_L1" : "Jerry 同学",
    "JERRY_L2" : "美国伦斯勒理工学院<br>Rensselaer Polytechnic Institute<br>工程专业 大一学生",
    "JERRY_L3" : "求学历程：温州公立小学&中学<br>上海公办高中<br>美国 Hebron Academy 高中<br>美国伦斯勒理工学院",
    "JERRY_L4" : "2015年1月底：路由协助开始申请美高<br>2015年2月底：拿到美国Hebron Academy暑期班及11年级Offer<br>2015年8/16～9/6：参加录取高中Hebron Academy的暑期班<br>2015年9月～2017年6月：就读11年级，12年级<br>2016年9月初：开始着手申请美国大学<br>2017年1月中旬：收到伦斯勒理工学院  提前录取（ED）通知<br>2017年9月～至今：伦斯勒理工学院  工程专业  大一学生",
    "SEAN_L1": "新西兰私立基督中学<br>Christ's College 13年级",
    "SEAN_L2": "13年级的学生Sean很喜欢学校提供的各种各样的科目，而且他很享受可以自己选课的自由。 “在中国，学校里只有安排好的课程表，但在新西兰，你的课程表是由你来管理你的时间，你可以选择自己喜欢的活动和想要学习的科目。”Sean最感兴趣科目的是艺术（设计和绘画）。他想要保持自己的创造性，并想把设计发展为自己的未来职业。",
    "SEAN_L3": "Sean在夏季参加了田径运动会，并且在冬季踢足球。同时，他也很享受学校提供的多种多样的课余活动，他说这些有趣的活动是一个结识新朋友的好方式。",
    "SEAN_L4": "求学历程：<br>温州公立小学<br>杭州公立中学<br>新西兰基督城男子私立基督中学",
    "SEAN_L5": "2013年3月底：前来委托<br>2014年4月中旬：拿到美国暑期班Offer<br>2014年6/30～7/26：参加美国Portsmouth Abbey高中暑期班<br>2014年8月～12月：路由定制化英语加强训练& 模拟国外高中生活作息<br>2014年11月中旬：拿到新西兰 基督中学Christ’s College录取通知<br>2015年1月：基督城四周语言学校熟悉环境<br>2015年2月～至2018年1月：入学10年级～现读13年级",
    "SEAN_L6": "<u>新西兰Christ's College高中三年留学点滴</u>",
    "SEAN_L7": "很多人问我，在新西兰留学，是怎样的体验？大家都以为，除了抱羊还是羊 :) :-]   那你就错了，往下看看我三年来在基督城寄宿高中的校内外活动。",
    "SEAN_L8": "我的学校是一所沿袭典型英式贵族传统的私立名校，源自英国国教的教会学校。是新西兰最古老的私立男子中学，已有超过150年的历史。英式精英的培养方式，位处于基督城市中心，一边紧邻着世界第二大的海格力公园，另一边为坎特伯雷博物馆。校园以最新的建筑标准融合保留最古老的传统建筑外观，校园里还有一座壮观的教堂保留着。地震后学校一共投入了人民币约4亿多元做了老建筑加固。学习中心里的课后1对1单独辅导，是不另外收费的。学生一律穿校服，而所有校服均有专人负责洗烫。为训练学生自理能力，便服则是需要学生自己用宿舍内洗衣机洗。",
    "SEAN_L9": "学校的日常生活作息规定非常完整、严密。起床、吃早餐、上下课时间，下课后每个人都必须参加体育俱乐部，锻炼结束回宿舍洗澡、晚自习到熄灯时间。所有事情都有严格的时间安排。学生其实每天的自由时间有限，不是在学习，就是在锻炼体能。",
    "SEAN_L10": "宿舍里面有楼长、生活老师、教学辅导员三组人马。宿舍墙上，刻着住过的学生名字。宿舍里有学生自习的教师，晚上有专门的老师陪同。睡觉前，学生的手机电脑必需交给老师锁起来。强调集体宿舍成长，强烈的集体荣誉感、团队配合、分享。共同生活的经验。",
    "SEAN_L11": "<p><u>家长心路历程分享</u></p><p>遇见路由的Michelle老师之前，我是一个彷徨无助的妈妈，看着体弱多病的儿子经常去医院边打点滴边写作业很心痛也无耐。成绩能还在不上不下中徘徊，性格越来越沉默，自信全无。直到有一天，他放学回家很伤心的和我说：“妈，我在班里被划为“垃圾那类了”，我瞬间奔溃意识到不能这样下去了，我带着儿子求助了Michelle。她马上着手安排和规划在最短的时间里安排好了儿子暑期去美国上暑期班事宜，直到两月后我们去接他，看到了他用蹩脚的英文去和人沟通一次又一次而且脸上是笑容满面的自信，这是我们无法想像的。而后我们在Michelle的安排下，居然申请到了新西兰最古老的私立中学。排名前十的基督中学 Christ’s College。我们在感恩上帝的同时也十分感谢Michelle的用心和付出，到现在已经过去了快三年的时间，我儿子身上可以说发生了翻天覆地的变化：戏剧、绘画、音乐、体育一样没少，还是个社交活动上的活跃分子。最主要的是身体强壮了，开朗、自信回到了他身上。而这三年儿子留学期间，也还好有Michelle帮我们除了翻译成绩单、缴交学费外，协助选科，跟老师沟通学期学习进度，讨论及安排需要加强的项目。跟儿子定期保持联系，了解他的学术及生活状况，鼓励他从事乐器学习，课后社团与运动等等。也是我们跟监护人之间的沟通桥梁，安排学期结束后的放假去处。很多没想到的事，Michelle都提前提醒我们。儿子的成长，我们看在眼里。谢谢Michelle，因为有你，我们很安心。</p>",
    "SEAN_L12": `<p><u>Sean的感想</u></p><p>我讲讲我到新西兰这三年来的收获。刚刚来到新西兰的时候，我英语还没有现在那么好。只是我没有害羞去和外国人交谈，所以不仅交到许多外国朋友，还有我的英语口语也随之进步了许多。还有一件事情我觉得比较重要的是刚到学校的时候，不要忘记多多去尝试从来没有尝试过的东西。像当地人爱玩的体育项目我们国际生也可以去加入。当然也可以问当地人教我们玩，这其实也是融入当地人团体的方式。不仅是体育项目，还有要多尝试不同的科目，这样方便以后选到自己喜爱的科目。对于我来说，我现在选到了我一直感兴趣的设计和油画，这有点走艺术范。</p>
                  <p>总而言之，我觉得到不论是国外读书、旅游或是工作，要敢于和外国人交流，这样对我们国际生的口语方面帮助是非常大的。外国人很爱运动，多去玩玩运动也是交朋友的方式。</p>
                  <p>顺便讲讲我是怎么顺利的进入Christ‘s College的。我在中国的时候，我也是不敢跟金发碧眼的国外人交流的。转折点就是，我有一位超级美丽的英语老师Teacher Ti。苦口婆心的一直反复锻炼我的英语，所以和外国人交流时，不会小鹿乱撞。而在这位美似天仙级别的英语老师的天仙（魔鬼）训练下，我那时候敢和外国人沟通并且可以开几个玩笑。在这里要再次谢谢 Teacher Ti 对我的帮助。不仅仅有Ti老师对我英语的锻炼，还有Michelle老师帮我找到这么一所学校。我认为这所学校真的给了我人生的一个转折点。Michelle老师是我的引路人，我一路能够顺利的进入这所学校，少不了她一直给学校写邮件，还有少不了她教我如何应对面试。总之，没有Michelle 和 Ti 对我的帮助，或许我的转折点也不会出来。</p>
                  `,
    "TIMMY_L1": `<p>曼彻斯特大学位于城市的市中心，生活出行很方便，所以到曼彻斯特不久即使不怎么习惯独立生活也能马上适应那里的生活。</p>
                <p>宿舍有投币洗衣机，小超市快餐店也有很多宿舍步行就能到学校，又没有早课可以充分保证睡眠。课不是每天都有，形式多样化，有大教室的讲座也有小组形式的课程，辅导老师比起教授很多，知识更主要是给予指导所以想要拿到好成绩需要自己多看书，能锻炼自己学习的能力。</p>
                <p>学校有很大的图书馆和学习中心，平时总是挤满了学生，气氛很棒，在曼彻斯特可以得到很好的学习体验。</p>
                `,
    "LI_L1" : "遇见Route Ed.之前，我是一个彷徨无助的妈妈，看着体弱多病的儿子经常去医院边打点滴边写作业很心痛也无耐，成绩能还在不上不下中徘徊，性格越来越沉默，自信全无。直到有一天，他放学回家很伤心的和我说：“妈在班里被划为“垃圾那类了”，我瞬间奔溃意识到不能这样下去了，我带着儿子求助了Route Ed.，她們马上着手安排和规划在最短的时间里安排好了儿子暑期去美国上暑期班事宜，直到两月后我们去接他，看到了他用蹩脚的英文去和人沟通一次又一次而且脸上是笑容满面的自信的，这是我们无法想像的，而后我们在Route Ed.的安排下居然申请到了新西兰排名前十的基督教男子中学，我们在感恩上帝的同时也十分感谢Route Ed.的用心和付出，到现在已经过去了两年半的时间，我儿子身上可以说发生了翻天覆地的变化，戏剧、绘画、音乐、体育一样没落还是个社交活动上的活跃分子，最主要的是身体强壮了，开朗、自信回到了他身上，再次感谢Route Ed.的帮助。",
    "WANG_L1" : `<p>去年四月经亲戚介绍认识了陈老师，从我们开始有意向送儿子出国留学到申请成功只用了短短的五个月时间。说起我儿子真是有一肚子的苦水，他从初中开始就是一个问题少年，上学经常被老师批评，最后发展到逃学。就这样在我们家长软硬兼施下勉勉强强上完高中。高考也没参加就外出四处游荡瞎折腾了好几年，也混不出什么名堂。我们全家都为之苦恼，束手无策！遇到陈老师后我就把我儿子的情况和我们家长的想法告诉她，请她帮忙设计我儿子重上求学之路。</p>
              <p>后来陈老师约我儿子与她见面，通过了解交谈，陈老师认为我儿子这几年的社会闯荡和挫折内心有求学的欲望。在陈老师的帮助和指导下，一切申请手续都很顺利。于2016年9月下旬踏上了新西兰留学之路！实现了大学梦！</p>
              <p>我儿子出国留学已整整一年时间了，现在已完成预科课程进入大一班学习。我们从起初担心到后来的放心再到现在的全家开心。在出国留学前我们还怕儿子基础差又缀学这么多年会坚持不下来，可是到了林肯大学后就被这里的环境和老师深深地吸引住了，儿子就像换了一个人似的，一向厌学的他对学习开始感兴趣起来了。平时也愿意跟我们交流商讨在校一些学习情况。最近又传来捷报三门功课名列前茅，我们悬着的心终于放下来了。这些成绩的获取离不开儿子的努力和付出，希望儿子能再接再厉，再上一个台阶！同时也离不开陈老师的一路关心和指导，每次遇到问题时她总是不厌其烦帮助和支持，并主动与学校老师联系解决我们的实际困难。特别是今年8月份还专程赴新西兰学校拜访任课老师了解学生在学校的学习和生活情况。在此我们表示衷心的感谢！祝愿陈老师生活幸福安康，事业兴旺发达！</p>
              `
  },
  "ONLINE" : {
    "L1" : "路由在提供留学规划的过程中，根据个别学生不同的需求，有专门一对一的老师可供学生额外线上上课，做针对性的辅导，提升语言能力或英语考试成绩以面对申请学校需求。"
  },
  "STUDY" : {
    "TAB1" : "留学服务",
    "TAB2" : "留学期间顾问服务",
    "TAB3" : "留学行前准备",
    "TAB1_L1" : `<h3>个性化、细节化的全方位留学服务</h3>
                  <p>留学是一个孩子成长的过程，而不是他（她）的人生终点。能支持孩子一路坚持走下去的，不是进了一间最好的学校。而是这个学校，是不是他（她）自己认为可以达成目标与梦想的所在。我们相信，每个孩子都有一份被上天隐藏的礼物，必须一次次追寻，才能获得。我们的使命，是一同和您探索孩子未来的路，寻找这份礼物。</p>
                  <p>出国留学并非只是把英文学好，走马看花那么简单。它是一个让孩子有机会走出国门，拓展视野的机会；经由脱离安全温暖的家，在外多去尝试不同事物，与不同的人相处，从中发觉自己的兴趣，找到最爱的目标。而有了动力，进而累积自己的专业。不同的旅程所带给生命的刺激，就像葡萄酒会经过岁月历练而发生奇妙的化学变化。不同的际遇，会开启孩子不同的未来之路。</p>
                  <p><b>先谋而后动</b></p>
                  <p>谋划准确周到而后行动。选校不要盲目。没有最好的学校，只有适不适合孩子。汇总专业留学顾问建议再决定。一系列的客制化方案，供您全方位的选择出最适合您家庭教育目标。</p>
                  <p><b>谋</b>：经由深入了解，锁定诱因，找出动力，全盘考量，顾问团队整合制定多视角的综合建议及长期规划。</p>
                  <p><b>动</b>：出国前能力培养，选出合适的学校，申请入校，后留学学术及生活规划</p>
                  <p>路由致力于帮助客户在繁复的学校申请流程中，提供充足的咨询，全面的协助，以及专业的建议。经验丰富且热情的专业顾问，经由紧密的与学生及家长合作，帮助选定最适合的近程及远程目标，订立计划。</p>
                  <p>我们坚信，拿到学校的录取通知书并不是唯一的终极目标。我们期望除了被录取的结果之外，能在申请过程当中，让孩子准备好，迎接将来校园里的社交与学术挑战。我们除了希望成为您的得力左右手，也希望是良师益友，一同帮助孩子成为明日的国际领导者。这是路由的初心。每个学生都是独一无二的。我们将尽全力为您及您的宝贝找出最适合他的学校。无论您是为您的孩子寻找一个短期夏令营，暑期班，或安排出国读书留学规划及学校申请。我们的顾问团队将以专业的手，热情的心，提供一系列的客制化方案，帮助您达成您的家庭教育目标。</p>
                  <p>路由与您一起探索孩子未来的路。</p>
                  <p>成功之路，由此开启。</p>
                  `,
    "TAB2_L2" : `<img src="assets/img/study/Ongoing1.jpg" style="margin:.5em; width: 100%; max-width: 200px; float:left;" /><p>路由的任务，不停止于只是送学生出国读书。我们的宗旨是帮助学生，有更巩固的基础及高效的学习方法。并保持心态健康，积极面对生活及人际关系。路由的留学期间顾问服务，在协助完成学校申请后，更提供您下个阶段的协助，例如：安排监护人；协助选科；成绩单翻译，跟老师沟通学期学习进度，讨论及安排需要加强的项目。跟学生保持亦师亦友的关系，了解孩子的学术及生活状况，进而鼓励他们从事休闲娱乐，例如：乐器学习，课后社团与运动等等。使其能独立自主的为人生负责任地做有效安排。此外，我们亦协助与校方一同安排学期结束后的放假去处。建议是否再进一步参与学校的暑期班课程。辅导给付下一学年度学费账单等。</p>
                  <p>不同于其他中介留学规划的服务，我们每个学生将由多个顾问根据他们自身的经历及专业，提供多视角的综合建议及长期留学生活规划。进入一所学校就读，只是孩子人生的转折起点。负责的教育，是我们一同一路陪伴着孩子，从不同的角度，持续性的引导及见证成长。</p>
                  <p>我们除了希望成为您的得力左右手，也希望是良师益友，一同帮助孩子成为明日的国际领导者。这是路由的初心。</p>
                  `,
    "TAB3_L3" : `<div class="text-center"><img src="assets/img/study/checklist.jpg" style="margin: .5em; width: 100%; max-width: 200px;" /></div>
                <p>恭喜你拿到Offer成为准留学生。最困难的阶段已经闯关成功。接下来是留学行前需要注意的准备事项。</p>
                <ol>
                <li>护照 & 学生签证检查护照有效期是否在六个月以上 & 学生签证信息是否正确</li>
                <li>购买机票</li>
                <li>安排接机 & 住宿</li>
                <li>体检:<br>出发前提早至少三周，请至各地市卫生检疫局体检并开具（a）国际预 防接种证明和（b）国际旅行健康证国际通用（黄卡)。</li>
                <li>拜访牙医&眼科:<br>国外看牙医和眼科不涵盖在国际学生医疗保险内。而且费用非常高。尽可能在国内先拜访牙医跟检查眼睛，配好需要的眼镜或隐形眼镜带出國。</li>
                <li>手机卡开通全球通业务:<br></li>
                <li>请在出国前一天暂时开通国内手机卡的全球通业务。或者在淘宝买一张  国际电话卡，以便到达后给家长报平安和联系学校接机用。</li>
                <li>笔记型电脑:<br>通常国外从高中阶段学生开始使用笔记型电脑做功课。可事先了解学校  倾向学生使用的电脑相关规格，从中国购买带过去或者从学校订购。</li>
                <li>购买旅游平安险 & 国际学生医疗保险:<br>旅游平安险可直接网上购买国内保险公司所提供的。只需输入基本信息跟航班日期。国际学生医疗保险需依照学校规定购买。建议直接请学校代买</li>
                <li>文件准备:<br>为方便文件被窃或遗失时，能迅速申请补发，所有下列清单物品最好事先扫描留档。所有正本随身携带。全部文件复印一份，复印件跟护照用证件照一起放托运行李内）(1) 机票 (2) 护照 (3) 学生签证  (4) 学校录取通知单 (5) 旅游平安险 (6) 国际学生医疗保险 (7) 几张护照用的证件照 (8) 学生本人姓名的国际通用信用卡（通常为家长的副卡）。</li>
                <li>行李打包:<br>
                    <ul>
                      <li>行李箱:<br>各航空公司跟搭乘舱等规定不同，一般免费带1～2件托运行李箱（28～30寸，行李箱三边之和小于158公分。单件重量通常不超过20～23公斤，学生可以申请额外限额）。一个随身行李箱（通常为20寸，行李箱三边之和小于118公分。单件重量不超过5～10公斤<br>
                      常用药品 - 携带的药物最好放在原装并有标示的瓶罐或盒子里。如因药物较特别或含有麻醉品成分，要携带医生开给的证明。自备惯用的药品要避免携带粉状药物，以免被误为毒品。在对携带药物入境是否合法有疑问时，应事先咨询该国驻华机构或我国驻外使馆。<br>
                      充电器 & 转换插头 - 请先查询目的地国家的电压。备好充电器和转换插头。不建议携带电器产品避免危险。飞机充气靠枕长途飞行时可增加舒适度。</li>
                      <li>中国特色小礼物:<br>可以准备一些送给老师或者寄宿家庭。</li>
                      <li class="color-red">严禁携带：水果，蔬菜，种子，肉制品，盗版光碟或书籍等。轻者会没收，重者有处分</li>
                    </ul>
                </li>
                </ol>
                `
  },
  "STUDY_SHORT": {
    "COMMON_L1": "规划中",
    "COMMON_L2": "下载详细内容",
    "2018_L1": "<h1>路由2018之约</h1><h2>暑期微留学体验营@新西兰</h2><h3>« 路由去哪儿»</h3>2018@新西兰<br>那一年，我们在基督城避暑",
    "2018_L2": "<h3>行程资讯</h3><p>2018年<br>7月20日～8月31日<br>各梯次出行日期：<br>7/20；8/3；8/17<br>（两周起报）<br>招生人数<br>4～12个学位<br>招生年龄<br>5～12岁<br>安插当地小学<br>12～18岁<br>安插当地中学&高中<br>12周岁以下的学生必需至少有一名家长全程陪读</p>"
  }
};
